import React from 'react'
import './Section2.css'
import image from '../../images/section2.jpg'

const Section2 = () => {
  return (
    <section className='section2OuterContainer'>
        <div className='section2Header'>
            <h2 className='font-family'>راحة بالك على رأس أولوياتنا</h2>
            <p className='font-family'>تعرّف على ما نتيحه لك لمساعدتك على استقبال الضيوف بثقة أكبر:</p>
        </div>
        <div className='section2InnerContainer'>
            <div className='section2ImageContainer'>
                <img src={image} alt='section2'/>
            </div>
            <div className='section2Checks'>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>إعداد <span className='font-family'>القواعد الداخلية</span> التي يجب أن يوافق عليها الضيف قبل إقامته</p>
                </div>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>القدرة على <span className='font-family'>الإبلاغ</span> في حالة عدم حظور الضيف</p>
                </div>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>الإبلاغ عن <span className='font-family'>سوء سلوك الضيوف</span> إذا حدث خطأ ما</p>
                </div>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>توفّر <span className='font-family'>الدعم على مدار الساعة</span> بأكثر من لغة</p>
                </div>
                {/* <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>الحماية ضد مطالبات المسؤولية من الضيوف والجيران، بما يصل إلى 500 دولار أمريكي لكل حجز</p>
                </div> */}
            </div>
        </div>
    </section>
  )
}

export default Section2