import React from 'react'
import './Section1.css'
import ShowPlace from '../ShowPlace/ShowPlace'

const Section1 = () => {
  return (
    <section id='section1' className='section1OuterContainer'>
        <div className='section1InnerContainer'>
            <div className='section1RightContainer'>
                <p className='section1RightHeadline font-family'>اعرض <br /> 
                    <span className='words font-family'>
                        <span className='font-family'>أي مكان للاقامة</span>
                        <span className='font-family'>شقتك</span>
                        <span className='font-family'>فندقك</span>
                        <span className='font-family'>فيلتك</span>
                        <span className='font-family'>منتجعك</span>
                    </span> 
                    على حجوزات
                </p>
                <p className='section1RightDesc font-family'>التسجيل مجاني و يستغرق 15 دقيقة فقط، ابدأ اليوم</p>
                <h5 className='font-family section1Desc'>مجانا بدون عمولات. يمكنك إدارة حجوزاتك الداخلية الخاصة بفندقك عبر منصة حجوزات، و معرفة حالة الغرف بكل سهولة.</h5>
            </div>
            <div className='section1LeftContainer'>
                <div className='section1LeftInnerContainer'>
                    <ShowPlace section1={true} />
                </div>
            </div>
        </div> 
    </section>
  )
}

export default Section1