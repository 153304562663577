import React from 'react'
import './Section3.css'
import Benefits from '../Benefits/Benefits'
import B1 from '../../images/b1.svg'
import B2 from '../../images/b2.svg'
import B3 from '../../images/b3.svg'
import B4 from '../../images/b4.svg'
import B5 from '../../images/b5.svg'
import B6 from '../../images/b6.svg'

const Section3 = () => {
  return (
    <section className='section3OuterContainer'>
        <div className='section3Header'>
            <h2 className='font-family'>مزايا العمل معنا</h2>
        </div>
        <div className='section3InnerContainer'>
            <Benefits icon={B1} header={'اعرض أيّ نوع من العقارات'} text={'يمكنك عرض كل أنواع الأماكن مجاناً، من الشقق إلى الفيلات وغيرها الكثير.'}/>
            <Benefits icon={B2} header={'حمّل بيانات عقارك بسهولة'} text={'لتوفير الوقت عليك، سهّلنا لك عملية تحميل الكثير من بيانات عقاراتك التي تعرضها على مواقع أخرى.'}/>
            <Benefits icon={B3} header={'إرشادات مفصّلة خطوة بخطوة'} text={'تعرّف على كيفية عمل منصتنا وأفضل الممارسات والأمور التي يجب أن تعيرها انتباهك.'}/>
            <Benefits icon={B4} header={'خصومات مميزة'} text={'خصومات على خدمات ومنتجات تتيح لك توفير الوقت وتحسين تجربة الضيوف.'}/>
            <Benefits icon={B5} header={'المزيد من الخيارات المرنة من دون أي تكاليف إضافية'} text={'مع برنامج حجوزات Smart Flex، يمكن لضيوفك الاستمتاع بمرونة سياسات الإلغاء المجاني. في حالة الإلغاء، سنبحث عن ضيف بديل.'}/>
            <Benefits icon={B6} header={'نقاط التقييم'} text={'قد يتم استخدام نقاط التقييم الخاصة بك على مواقع السفر الأخرى، بحيث يتم تحويلها إلى مقياس وعرضها على صفحتك في موقعنا.'}/>
        </div>
    </section>
  )
}

export default Section3