import React from 'react'
import ShowPlace from '../ShowPlace/ShowPlace'
import '../Section1/Section1.css'

// CSS IMPORTED FROM SECTION 1

const Section7 = () => {
  return (
    <section className='section7OuterContainer'>
        <div className='section1InnerContainer'>
            <div className='section1RightContainer'>
                <p className='section7RightHeadline font-family'>سجّل وابدأ باستقبال<br /> الضيوف اليوم!</p>
            </div>
            <div className='section1LeftContainer'>
                <div className='section1LeftInnerContainer'>
                    <ShowPlace section1={false} />
                </div>
            </div>
        </div>

        <div className='section7Footer'>
            <div className='section7Right'>
                <a className='font-family' href='../about-us' target='_blank'>نبذة عنا</a>
                <span></span>
                <a className='font-family' href='../privacy' target='_blank'>بيان الخصوصية وملفات تعريف الارتباط</a>
                <span></span>
                <a className='font-family' href='../terms-conditions' target='_blank'>الأحكام و الشروط</a>
            </div>
            <div>
                <p className='publish-rights font-family'>حقوق النشر حجوزات 2024 </p>
            </div>
        </div>
    </section>
  )
}

export default Section7