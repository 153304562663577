import React from 'react'
import image from '../../images/section4.jpg'
import '../Section2/Section2.css'

// IT IS AS SAME AS SECTION2
const Section4 = () => {
  return (
    <section className='section2OuterContainer'>
        <div className='section2Header'>
            <h2 className='font-family'>تعرّف على ضيوفك</h2>
            <p className='font-family'>هناك بعض الأمور المشتركة بين ضيوفنا، بغض النظر عن اختلافاتهم.</p>
        </div>
        <div className='section2InnerContainer'>
            <div className='section2ImageContainer'>
                <img src={image} alt='section2'/>
            </div>
            <div className='section2Checks'>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>يتم حجز 75% من الليالي على موقعنا من قِبل ضيوف أجروا 5 حجوزات أو أكثر سابقاً</p>
                </div>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>يتم حجز 68% من الليالي على موقعنا من قِبل العائلات والأزواج</p>
                </div>
                <div className='section2OneCheck'>
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                    <p className='font-family'>يتم حجز 42% من الليالي في أماكن إقامة أخرى غير الفنادق</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Section4