import "../Privacy/Privacy.css";
import React from "react";
import Navbar from "../Navbar/Navbar";

const NonDiscrimination = () => {
  return (
    <div className="termsOuterContainer">
      <Navbar />
      <div className="privacyInnerContainer">
        <section className="section1">
          <h1>سياسة عدم التمييز</h1>
          <p className="font-family">
            إن Hujwzat في الأساس عبارة عن مجتمع مفتوح يهدف لتقريب العالم من خلال
            تعزيز الخبرات المشتركة والهادفة بين الناس من جميع أنحاء العالم. ويضم
            مجتمعنا ملايين الأشخاص من كل بلد في العالم تقريبًا. إنه مجتمع متنوع
            بشكل مذهل، ويجمع بين الأفراد من مختلف الثقافات والقيم والأعراف.
          </p>
          <br />
          <p className="font-family">
            يلتزم مجتمع Hujwzat ببناء عالم يشعر فيه الناس من جميع الخلفيات
            بالترحيب والاحترام، حتى في بُعدهم عن وطنهم. يستند هذا الالتزام إلى
            مبدأين أساسيين ينطبقان على مضيفي Hujwzat والضيوف على حد سواء: الإدماج
            والاحترام. إن التزامنا المشترك بهذه المبادئ يمكِّن كل عضو في مجتمعنا
            من الشعور بالترحيب على منصة Hujwzat بغض النظر هويتهم، أو بلدهم، أو
            دينهم، أو من يحبون. كما وتدرك Hujwzat أن بعض السلطات القضائية تسمح أو
            تطلب تمييزًا بين الأفراد استنادًا إلى عوامل مثل، الأصل القومي، أو
            الجنس، أو الحالة الاجتماعية، ولا تتطلب من المضيفين انتهاك القوانين
            المحلية أو اتخاذ إجراءات قد تعرضهم للمسؤولية القانونية. سوف تقوم
            Hujwzat بتوفير توجيه إضافي وتعديل سياسة عدم التمييز هذه لتعكس هذه
            التصاريح والمتطلبات المفروضة في السلطات القضائية التي تتواجد فيها.
          </p>
          <br />
          <p className="font-family">
            بينما لا نعتقد أنه يمكن لشركة واحدة أن تفرض الانسجام والتوافق بين
            جميع الناس، فإننا نعتقد أن مجتمع Hujwzat يمكن أن يعزز التعاطف
            والتفاهم بين جميع الثقافات. ونحن ملتزمون جميعًا ببذل كل ما في وسعنا
            للمساعدة في القضاء على جميع أشكال التحيز غير القانوني والتمييز
            والتعصب من منصتنا. ونريد تعزيز ثقافة داخل مجتمع Hujwzat تتجاوز حد
            الامتثال للقانون فقط، سواء كان ذلك بين المضيفين أو الضيوف أو الأشخاص
            الذين يفكرون في استخدام منصتنا. ولتحقيق هذه الغاية، نوافق جميعنا،
            موظفو شركة Hujwzat والمضيفون والضيوف على حد سواء، على القراءة والعمل
            وفقًا للسياسة التالية لتقوية مجتمعنا وتحقيق رسالتنا المتمثلة في ضمان
            أن كل شخص يمكن أن ينتمى لمجتمعنا ويشعر بالترحيب في أي مكان.
          </p>
          <br />
          <ul className="bold-head-list">
            <li>الإدماج:</li>
            <p>
              نرحب بالأشخاص من جميع الخلفيات بقلوب مضيافة وعقول منفتحة. ويعني
              الانضمام إلى Hujwzat، كمضيف أو ضيف، أن تصبح جزءًا من مجتمع يرحب
              بالإدماج. فلا مكان للتحيز، والتعصب، والعنصرية، والكراهية على
              منصتنا أو في مجتمعنا. في حين يُطلب من المضيفين اتباع جميع القوانين
              المعمول بها التي تحظر التمييز على أساس عوامل مثل العرق، والدين،
              والأصل القومي، وغير ذلك من الأمور المدرجة أدناه، فإننا نلتزم
              بالقيام بأكثر من مجرد الامتثال للحد الأدنى من المتطلبات التي ينص
              عليها القانون.
            </p>
            <li>الاحترام:</li>
            <p>
              نحن نحترم بعضنا البعض في تفاعلاتنا ومقابلاتنا. وتقدّر Hujwzat أن
              القوانين المحلية والأعراف الثقافية تختلف في جميع أنحاء العالم
              وتتوقع من المضيفين والضيوف الإلتزام بالقوانين المحلية، والاختلاط
              ببعضهم البعض باحترام، حتى عندما لا تعكس الآراء معتقداتهم أو
              نشأتهم. يجلب أعضاء Hujwzat إلى مجتمعنا تنوعًا مذهلاً متعدد الخلفيات
              من خبرات ومعتقدات وعادات. فمن خلال ربط ناس من خلفيات مختلفة ببعضهم
              البعض، تشجع Hujwzat على فهم وإدراك الخصائص المشتركة التي يتقاسمها
              جميع الناس، وتهدم التحيز الذي ينبع من سوء الفهم، أو التضليل.
            </p>
          </ul>
        </section>
        <section className="privacySection">
          <h2>توجيهات محددة للمضيفين في اليمن</h2>
          <p className="font-family">
            بشكل عام، سنلتزم بالتعرف على جميع القوانين الفيدرالية والقوانين
            الوطنية والقوانين المحلية السارية في الجمهورية اليمنية التي تنطبق
            على السكن وأماكن الإقامة العامة. وقد تكون هناك بعض المتطلبات
            القانونية الإضافية في بعض المحافظات قد توسع نطاق حماية الحقوق
            المدنية لمجتمع المستخدمين أو تحد منها. يُطلب من المضيفين الالتزام
            بهذه المتطلبات القانونية. يجب على المضيفين الاتصال بخدمة عملاء
            Hujwzat إذا كانت لديهم أية أسئلة حول التزاماتهم بسياسة عدم التمييز
            هذه من Hujwzat. سوف تقوم Hujwzat بنشر المزيد من توجيهات سياسة عدم
            التمييز للسلطات المحلية في اليمن في المستقبل القريب. وبالاسترشاد
            بهذه المبادئ، سيتّبع مجتمع المضيفين في اليمن هذه القوانين عند
            التفكير في الضيوف المحتملين واستضافة الضيوف.
          </p>
          <br />
          <p
            className="font-family"
            style={{ fontWeight: 600, fontSize: "1.2rem" }}
          >
            العرق، أو اللون، أو الأصل القومي أو العرقي، أو الدين، أو الحالة
            الاجتماعية
          </p>

          <ul className="bold-head-list">
            <li>لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>
                رفض أي ضيف على أساس العرق، أو اللون، أو الأصل العرقي، أو الأصل
                القومي، أو الدين، أو الحالة الاجتماعية.
              </li>
              <li>
                فرض أي شروط أو حالات مختلفة على أساس العرق، أو اللون، أو الأصل
                القومي والعرقي، أو الدين، أو الحالة الاجتماعية.
              </li>
              <li>
                نشر أي إعلانات أو الإدلاء بأي تصريح غير مشجّع أو يشير إلى أي
                تفضيل لحساب أو ضد أي ضيف بسبب العرق، أو اللون، أو الأصل القومي
                والعرقي، أو الدين، أو الحالة الاجتماعية.
              </li>
            </ul>
          </ul>

          <p
            className="font-family"
            style={{ fontWeight: 600, fontSize: "1.2rem" }}
          >
            السن والحالة الأسرية
          </p>

          <ul className="bold-head-list">
            <li>لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>
                فرض أي شروط أو حالات مختلفة أو رفض الحجز على أساس عمر الضيف أو
                وضعه العائلي، في حال يحظر القانون ذلك.
              </li>
            </ul>

            <li>يجوز لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>
                تقديم معلومات دقيقة بشكل واقعي عن ميزات إعلانهم (أو عدم وجودها)
                والتي قد تجعل الإعلان غير آمن أو غير مناسب للضيوف من سن معينة أو
                العائلات التي لديها أطفال.
              </li>
              <li>
                إضافة ملاحظة في إعلانات مساكنهم حول جميع القيود المجتمعية
                المطبقة (مثل: استضافة كبار السن) أو القواعد أو القوانين التي
                تمنع استضافة ضيوف تحت سن معين أو العائلات التي لديها أطفال أو
                رُضع.
              </li>
            </ul>
          </ul>

          <p
            className="font-family"
            style={{ fontWeight: 600, fontSize: "1.2rem" }}
          >
            الاحتياجات الخاصة
          </p>

          <ul className="bold-head-list">
            <li>لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>رفض الضيف بسبب إعاقة فعلية أو ما قد يعتبرونه إعاقة.</li>
              <li>
                فرض شروط أو قيود مختلفة بناءً على أن الضيف من ذوي الاحتياجات
                الخاصة.
              </li>
              <li>
                تحديد إذا كان المسكن يلبي احتياجات ضيف من ذوي الاحتياجات الخاصة
                وفقًا لحكم المضيف الشخصي بدلًا من السماح للضيف المحتمل تحديد ما
                يناسبه بنفسه.
              </li>
              <li>
                الاستفسار عن وجود احتياجات خاصة لدى الضيف أو مدى شدتها، أو
                الوسائل التي يستخدمها الضيف للتأقلم مع احتياجاته. ولكن إذا أثار
                أحد الضيوف المحتملين موضوع احتياجاته الخاصة، فيجوز للمضيف (ويجب
                عليه) أن يناقش مع الضيف المحتمل ما إذا كان المسكن يلبي
                احتياجاته.
              </li>
              <li>منع أو تقييد استخدام وسائل المساعدة على الحركة.</li>
              <li>
                فرض رسوم أكثر على الضيوف ذوي الاحتياجات الخاصة، بما في ذلك رسوم
                الحيوانات الأليفة عندما يكون لدى الضيف حيوان للخدمة.
              </li>
              <li>
                نشر أي إعلانات أو الإدلاء بأي تصريح غير مشجّع أو يشير إلى أي
                تفضيل لحساب أو ضد أي ضيف بسبب حقيقة أن الضيف من ذوي الاحتياجات
                الخاصة.
              </li>
              <li>
                رفض التواصل مع الضيوف من خلال وسائل الوصول المتاحة، بما في ذلك
                خدمات الترحيل (للأشخاص الذين يعانون من ضعف السمع) والبريد
                الإلكتروني (للأشخاص الذين يعانون من ضعف البصر ويستخدمون أجهزة
                قراءة الشاشة).
              </li>
              <li>
                رفض تقديم تسهيلات معقولة، بما في ذلك المرونة عندما يطلب الضيوف
                من ذوي الاحتياجات الخاصة تغييرات متواضعة في قوانين بيتك، مثل
                استخدام موقف سيارات متاح بالقرب من الوحدة. عندما يطلب ضيف مثل
                هذه التعديلات، ينبغي على المضيف والضيف المشاركة في حوار لاستكشاف
                طرق مقبولة للطرفين لضمان أن المسكن يلبي احتياجات الضيف.
              </li>
            </ul>

            <li>يجوز لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>
                تقديم معلومات دقيقة واقعية عن ميزات إمكانية الوصول للوحدة (أو
                عدم وجودها)، مما يسمح للضيوف من ذوي الاحتياجات الخاصة بتقييم ما
                إذا كان المسكن مناسب لاحتياجاتهم الخاصة.
              </li>
            </ul>
          </ul>

          <p
            className="font-family"
            style={{ fontWeight: 600, fontSize: "1.2rem" }}
          >
            التفضيلات الشخصية
          </p>

          <ul className="bold-head-list">
            <li>لمضيفي Hujwzat:</li>
            <ul className="nested-inside-nested">
              <li>
                باستثناء ما ذكر أعلاه، يمكن لمضيفي Hujwzat رفض الحجوزات بناءً على
                عوامل لا يحظرها القانون. مثلًا، باستثناء الحالات التي يحظرها
                القانون، يجوز لمضيفي Hujwzat رفض حجوزات الضيوف الذين يصطحبون
                حيوانات أليفة أو الضيوف المدخنون.
              </li>
              <li>
                الطلب من الضيوف احترام القيود المفروضة على الأطعمة التي يتم
                استهلاكها في المسكن (على سبيل المثال، يجوز للمضيف الذي يحافظ على
                مطبخ نباتي أو موافق للشريعة اليهودية أن يطلب من الضيوف احترام
                هذه القيود). يجب ذكر هذه القيود بوضوح في قوانين بيتك. على سبيل
                المثال، يجوز لمضيفي Hujwzat رفض حجوزات الضيوف الذين يرغبون
                بالتدخين داخل المسكن، ويجوز لهم تحديد عدد الضيوف في المسكن.
              </li>
            </ul>

            <li>عندما يتم رفض الضيوف.</li>
            <ul className="nested-inside-nested">
              <li>
                على المضيفبن مراعاة أن لا أحد يحب أن يقابل بالرفض. على الرغم من
                أن المضيف قد يكون لديه أسباب واضحة وقانونية لرفض أحد الضيوف
                المحتملين، كما قد يوضح أسبابًا مشروعة، فقد يؤدي ذلك إلى شعور عضو
                مجتمعنا بأنه غير مرحب به أو مُستبعد. لذلك يجب على المضيفين بذل
                كل جهدهم للترحيب بالضيوف من جميع الخلفيات. إن المضيفين الذين
                يظهِرون نمط برفض ضيوف من فئة محمية (حتى إذا ذكروا أسبابًا
                مشروعة) يهدمون قوة مجتمعنا من خلال جعل الضيوف المحتملين يشعرون
                بأنهم غير مرحب بهم، وقد تقوم Hujwzat بتعليق حسابات المضيفين الذين
                يظهرون مثل هذا السلوك.
              </li>
            </ul>
          </ul>

          <h2>ماذا يحدث عندما لا يلتزم المضيف بسياساتنا في هذا المجال؟</h2>
          <p className="font-family">
            إذا احتوى إعلان معين على لغة مخالفة لسياسة عدم التمييز هذه، فسوف
            يُطلب من المضيف إزالة اللغة والتأكيد على فهمه أو نيته للامتثال لهذه
            السياسة والمبادئ الأساسية الخاصة بها. قد تقوم Hujwzat أيضًا، حسب
            تقديرها، باتخاذ خطوات تصل إلى - وبما في ذلك - تعليق حساب المضيف على
            منصة Hujwzat.
          </p>
          <br />
          <p className="font-family">
            إذا رفض المضيف الضيوف بشكل غير عادل على أساس الانتماء إلى فئة محمية،
            أو إذا استخدم تعبيرات تعكس أنه يتصرف بدوافع محظورة بموجب هذه
            السياسة، فستتخذ Hujwzat خطوات لفرض هذه السياسة، بما في ذلك - تعليق
            حساب المضيف من المنصة.
          </p>
          <br />
          <p className="font-family">
            مع نمو مجتمع Hujwzat، سنستمر في التأكد من أن سياسات وممارسات Hujwzat
            تتماشى مع أهم أهدافنا وهو: ضمان شعور الضيوف والمضيفين بالترحيب
            والاحترام في جميع تفاعلاتهم باستخدام منصة Hujwzat. فالجمهور،
            ومجتمعنا، ونحن أنفسنا، لا نتوقع أقل من هذا.
          </p>
          <br />
        </section>
      </div>
    </div>
  );
};

export default NonDiscrimination;
