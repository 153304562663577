import React from 'react'
import './Section8.css'
import shabua from '../../images/places/shabua.jpg'
import sanaa from '../../images/places/sanaa2.jpg'
import hadramaut from '../../images/places/hadramaut.webp'
import ibb from '../../images/places/ibb4.jpg'
import marib from '../../images/places/marib1.jpg'

const Section8 = () => {
  return (
    <section className='section8OuterContainer'>
        <div className='section8Header'>
            <h2 className='font-family'>الوجهات الرائجة</h2>
            <p className='font-family'>الخيارات الأكثر رواجاً للمسافرين من اليمن</p>
        </div>
        <div className='section8InneContainer'>
            <div className='onePlace'>
                <img src={shabua} alt='famous place'/>
                <p>شبوة - Shabwah</p>
                <div className='overlay'></div>
            </div>
            <div className='onePlace'>
                <img src={sanaa} alt='famous place'/>
                <p>صنعاء - Sana'a</p>
                <div className='overlay'></div>
            </div>
            <div className='onePlace'>
                <img src={hadramaut} alt='famous place'/>
                <p>حضرموت - Hadramawt</p>
                <div className='overlay'></div>
            </div>
            <div className='onePlace'>
                <img src={ibb} alt='famous place'/>
                <p>إب - Ibb</p>
                <div className='overlay'></div>
            </div>
            <div className='onePlace'>
                <img src={marib} alt='famous place'/>
                <p>مأرب - Marib</p>
                <div className='overlay'></div>
            </div>
        </div>
    </section>
  )
}

export default Section8;