import React from 'react'
import './Benefits.css'

const Benefits = ({icon, header, text}) => {
  return (
    <div className='oneBenefit'>
        <div className='benefitImage'>
            <img src={icon} alt='logo'/>
        </div>
        <h2>{header}</h2>
        <p>{text}</p>
    </div>
  )
}

export default Benefits