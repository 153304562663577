import React from "react";
import { useState } from "react";
import "./Section6.css";

const Section6 = () => {
  const [openQuestion, setOpenQuestion] = useState("");

  const questions = [
    {
      question: "كيف تفيد منصة حجوزات الفنادق؟",
      answers:
        "تساعد منصة حجوزات الفنادق على إدارة حجوزاتهم و إقامتهم و إيراداتهم بشكل أكثر كفاءة، مما يوفر الوقت و الموارد. كما أنه يوفر رؤى قيمة حول أداء الفنادق، مما يمكن الفنادق من إتخاذ قرارات مستنيرة و تحسين عملياتها.",
    },
    {
      question: "هل صفحة الحجز آمنة لاستخدام النزلاء؟",
      answers:
        "نعم، منصة حجوزات آمنة وتستخدم تقنيات تشفير متقدمة لحماية بيانات الضيف وضمان الخصوصية. كما أنه يتبع بروتوكولات الأمان المتوافقة مع معايير الصناعة لضمان سلامة وأمن معاملات النزلاء.",
    },
  ];

  const ChangeOpenState = (number) => {
    if (openQuestion === questions[number].question) {
      setOpenQuestion("");
    } else {
      setOpenQuestion(questions[number].question);
    }
  };

  return (
    <div className="section6OuterContainer">
      <div className="section6Header">
        <h2 className="font-family">أسئلة شائعة</h2>
      </div>

      <div className="faqQuestionsSection">
        <div className="faqQuestions">
          {questions.map((qu, index) => {
            return (
              <div key={index} className="faqOneQuestion">
                <div
                  onClick={() => ChangeOpenState(index)}
                  className={
                    openQuestion === questions[index].question
                      ? "faqQuestion faqQuestionOpened"
                      : "faqQuestion"
                  }
                >
                  <p className={"question"}>{qu.question}</p>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </div>
                <div
                  className={
                    openQuestion === questions[index].question
                      ? "faqOneQuestionAnswers faqOneQuestionAnswersOpen"
                      : "faqOneQuestionAnswers"
                  }
                >
                  <p className={"arabic_span"}>{qu.answers}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="section6Bottom">
        <a href={'./faqs'} target="_blank" rel="noreferrer" style={{textDecoration: 'underline'}} className="font-family">المزيد من الأسئلة الشائعة</a>
        {/* <p className="font-family">هل لديك المزيد من الأسئلة؟ تواصل معنا.</p>
        <a
          href="https://wa.me/967780219111"
          target="_blank"
          className="whatsapp-icon"
          rel="noreferrer"
        >
          <ion-icon name="logo-whatsapp"></ion-icon>
        </a> */}
      </div>
    </div>
  );
};

export default Section6;
