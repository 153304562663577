import Navbar from "../Navbar/Navbar";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div>
      <Navbar />
      <div className="not-found-container">
        <h1 className="not-found-title">لم يتم العثور على الصفحة</h1>
        <section className="error-container">
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
          <span className="zero">
            <span className="screen-reader-text">0</span>
          </span>
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
        </section>
        <div className="link-container">
          <a href="../../" className="more-link">
            الرجوع إلى الصفحة الرئيسية
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
