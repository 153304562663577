import React from "react";
import './CheckDiv.css'

const CheckDiv = ({sentence}) => {
  return (
    <div className="showPlaceTopCheck">
      <ion-icon name="checkmark-sharp"></ion-icon>
      <p className="font-family">{sentence}</p>
    </div>
  );
};

export default CheckDiv;
