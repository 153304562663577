import React from 'react'
import './Navbar.css'
import logo from '../../logo/Hujuzat.svg'

const Navbar = () => {
  return (
    <div className='navbarOuterContainer'>
      <div className='navbarInnerContainer'>
        <div className='navbarLogoContainer'>
          <a href='../' className='navbarLogo'>
            <img src={logo} alt='logo'/>
          </a>
          <a href={'../'} className='logoName font-family'>حجوزات</a>
        </div>
        
        <div className='navbarLinks'>
          <div className='navbarLinksRight'>
            <a href='https://wa.me/967780219111' target='_blank'  rel="noreferrer" className='whatsapp-icon'>
              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
            <p className='are-u-member font-family'>هل أنت شريك حالي؟</p>
          </div>
          <a className='navbar-login font-family' href='https://vendor.hujwzat.com/login' target='_blank'  rel="noreferrer">سجل الدخول</a>
        </div>
      </div>
    </div>
  )
}

export default Navbar