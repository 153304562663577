import React from 'react'
import './ShowPlace.css'
import CheckDiv from '../CheckDiv/CheckDiv'

const ShowPlace = ({section1}) => {
  return (
    <div className='showPlaceContainer'>
        <div className='showPlaceTopContainer'>
            <h2 className='font-family'>إضافة مكان اقامة جديد</h2>
            <CheckDiv sentence={'أغلب بيوت العطلات الفاخرة تم ادراجها على منصتنا'}/>
            <CheckDiv sentence={'تم تسجيل وصول أكثر من 1000 ضيف في بيوت العطلات'}/>
            <CheckDiv sentence={'تحصل أكثر من 20% من بيوت العطلات على حجزها الأول خلال أسبوع من إدراجها على منصتنا'}/>
        </div>
        <div className='showPlaceMiddleContainer'>
            <h2 className='font-family'>:أنشئ حساب شريك للبدء</h2>
            <span className='font-family'>بالاستمرار، فإنك توافق بأن حجوزات ستقوم بمراسلتكم عبر البريد الإلكتروني بشأن تسجيل مكان الإقامة الخاص بكم.</span>
            <a target='_blank' href='https://vendor.hujwzat.com/signup'  rel="noreferrer" className='showPlaceMiddleBtn font-family'>
                <ion-icon name="arrow-forward-outline"></ion-icon> <span>ابدأ</span>
            </a>
        </div>
       {section1 && 
       <div className='showPlaceBottomContainer'>
            <h2 className='font-family'>قمت ببدء عملية التسجيل مسبقاً؟</h2>
            <a className='font-family' target='_blank' href='https://vendor.hujwzat.com/login'  rel="noreferrer">تابع عملية تسجيلك</a>
        </div>}
    </div>
  )
}

export default ShowPlace