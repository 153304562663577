import {BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import { Suspense, lazy } from 'react';

import Home from './components/Home/Home';
import NonDiscrimination from './components/NonDiscrimination/NonDiscrimination';
import NotFound from './components/NotFound/NotFound';
const AllFaqs = lazy(() => import('./components/AllFaqs/AllFaqs'));
const Privacy = lazy(() => import('./components/Privacy/Privacy'));
const TermsConditions = lazy(() => import('./components/TermsConditions/TermsConditions'));
const AboutUs = lazy(() => import('./components/AboutUs/AboutUs'));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={' '}>
          <Routes>
            <Route path='/' exact element={<Home />}/>
            <Route path='/faqs' exact element={<AllFaqs />}/>
            <Route path='/privacy' exact element={<Privacy />}/>
            <Route path='/about-us' exact element={<AboutUs />} />
            <Route path='/terms-conditions' exact element={<TermsConditions />} />
            <Route path='/non-discrimination-policy' exact element={<NonDiscrimination />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
