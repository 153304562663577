import React from 'react'
import './Home.css'
import Section1 from '../Section1/Section1'
import Navbar from '../Navbar/Navbar'
import Section2 from '../Section2/Section2'
import Section3 from '../Section3/Section3'
import Section4 from '../Section4/Section4'
// import Section5 from '../Section5/Section5'
import Section6 from '../Section6/Section6'
import Section7 from '../Section7/Section7'
import Section8 from '../Section8/Section8'
import Section9 from '../Section9/Section9'

const Home = () => {
  return (
    <div className='homeOuterContainer'>
        <Navbar />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section8 />
        <Section9 />
        {/* <Section5 /> */}
        <Section4 />
        <Section6 />
        <Section7 />
    </div>
  )
}

export default Home;