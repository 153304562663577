import React from "react";
import "./Section9.css";
import '../Section2/Section2.css'

const Section9 = () => {
  return (
    <div className="section9OuterContainer">
      <div className="section9Innercontainer">
        <div className="embedVideo">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Ach3ZanGLgI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="nextEmbedVideo">
          <div className="nextEmbedHeader">
            <h2 className="font-family">طريقة الأشتراك</h2>
            <p className="font-family">نقدم لكم أسهل طريقة للأشتراك في منصتنا</p>
          </div>
          <div className="nextEmbedInnerContainer">
            <div className="nextEmbedChecks">
              <div className="nextEmbedOneCheck">
              <ion-icon name="person-circle-outline"></ion-icon>
                <p>
                  إنشىء حساب
                </p>
              </div>
              <div className="nextEmbedOneCheck">
              <ion-icon name="add-circle-outline"></ion-icon>
                <p>
                 أضف فندقك
                </p>
              </div>
              <div className="nextEmbedOneCheck">
                <ion-icon name="cash-outline"></ion-icon>
                <p>إضافة الغرف و الأسعار</p>
              </div>
              <div className="nextEmbedOneCheck">
                {/* <ion-icon name="duplicate-outline"></ion-icon> */}
                <ion-icon name="checkmark-done-outline"></ion-icon>
                <p>تحديد التوافر</p>
              </div>
              <div className="nextEmbedOneCheck">
                <ion-icon name="reader-outline"></ion-icon>
                <p>إستقبال الطلبات</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
